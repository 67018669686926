import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import _ from 'lodash'
import Layout from "../components/layout"
import 'bootstrap/dist/css/bootstrap.css'
//import "../styles/global.css"
//import "../styles/fonts.css"

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollIndicator = this.handleScrollIndicator.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  // Figure out why native react onScroll event isn't working
  componentDidMount() {
    this.contentSections = document.getElementsByTagName('section');
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    this.updateNavigation(e);
  }

  handleScrollIndicator(event) {
    event.preventDefault();
    this.scroll('our-story');
  }

  handleClick(event) {
    event.preventDefault();
    this.scroll(event.currentTarget.hash.substring(1));
  }

  scroll(hash) {
    document.querySelectorAll('section[id="' + hash + '"]')[0].scrollIntoView({ behavior: 'smooth' });
  }

  updateNavigation(e) {
    let windowHeight = e.target.scrollingElement.clientHeight;
    let scrollTop = e.target.scrollingElement.scrollTop;
    document.getElementsByClassName('Index-nav')[0].classList.add('overlay');
    _.each(this.contentSections, function (section) {
      //Figure out how to get the height of the menu
      let offsetTop = section.offsetTop + document.getElementsByClassName('Site loaded')[0].offsetHeight;
      if ((offsetTop - windowHeight / 2 < scrollTop) && (offsetTop + section.clientHeight - windowHeight / 2 > scrollTop)) {
        document.querySelectorAll('a[href="#' + section.id + '"]')[0].classList.add('active');

        if (section.id === 'our-story') {
          document.getElementsByClassName('Index-nav')[0].classList.remove('overlay');
        }
      } else {
        document.querySelectorAll('a[href="#' + section.id + '"]')[0].classList.remove('active');
      }

      if (scrollTop + windowHeight / 2 > document.getElementsByTagName('footer')[0].offsetTop) {
        document.getElementsByClassName('Index-nav-inner')[0].getElementsByTagName('a')[2].classList.add('active');
      }
    })
  }

  render() {
    const { data } = this.props;
    return (<Layout>
      <SEO title="Home" />
      <div className="Site-inner" id="yui_3_17_2_1_1538632099177_588">
        <header className="Header Header--bottom Header--overlay">
          <div className="Header-inner Header-inner--bottom" data-nc-group="bottom">
            <div data-nc-container="bottom-left">
            </div>
            <div data-nc-container="bottom-center">
            </div>
            <div data-nc-container="bottom-right">
              <nav className="Header-nav Header-nav--secondary" data-nc-element="secondary-nav"
                data-content-field="navigation">
              </nav>
            </div>
          </div>
        </header>
        <div className="Content-outer" id="yui_3_17_2_1_1538632099177_224">
          <div className="Loader"></div>
          <main className="Index">
            <section id="steep-deep-intense" className="Index-page Index-page--has-image" style={{ minHeight: 'calc(100vh - 116px)' }}>
              <div className="Index-page-scroll-indicator" onClick={this.handleScrollIndicator}>
                <div className="Index-page-scroll-indicator-text">Scroll</div>
                <svg className="Index-page-scroll-indicator-arrow Icon Icon--caretLarge--down" viewBox="0 0 48 23">
                  <use xlinkHref={data.file.publicURL + "#caret-down-large-icon"} />
                </svg>
                <div className="Index-page-scroll-indicator-line" />
              </div>
              <div className="Index-page-content  sqs-alternate-block-style-container" style={{ marginTop: 0 }}>
                <div className="sqs-layout sqs-grid-12 columns-12" id="page-5acd79e7aa4a999da47298de">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="sqs-block html-block sqs-block-html" id="block-c5433e96024ff87e2444">
                        <div className="sqs-block-content">
                          <h1 className="text-align-center">STEEP DEEP INTENSE</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <figure className="Index-page-image loaded" style={{ overflow: 'hidden' }}>
                <img alt="AFV Hero Image" src={data.afvHero.childImageSharp.original.src} style={{ fontSize: 0, left: 0, width: '100%', height: '1200.57px', position: 'relative', objectFit: 'cover', transform: 'translate(0%, -9%)' }} />
              </figure>
            </section>
            <section id="our-story" className="Index-page" data-collection-id="5ad765962b6a288cd1072c53" data-parallax-id="5ad765962b6a288cd1072c53" data-edit-main-image="Background">
              <div className="Index-page-content ">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on={1528241759086} id="page-5ad765962b6a288cd1072c53">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="sqs-block html-block sqs-block-html" data-block-type={2} id="block-yui_3_17_2_1_1528241461643_5699">
                        <div className="sqs-block-content">
                          <h1 className="text-align-center">STEEP DEEP INTENSE</h1>
                        </div>
                      </div>
                      <div className="sqs-block horizontalrule-block sqs-block-horizontalrule" data-block-type={47} id="block-yui_3_17_2_1_1528212858018_17886">
                        <div className="sqs-block-content">
                          <hr />
                        </div>
                      </div>
                      <div className="sqs-block html-block sqs-block-html" data-block-type={2} id="block-yui_3_17_2_1_1528212858018_17945">
                        <div className="sqs-block-content">
                          <h3 className="text-align-center">OREGON PINOT NOIR AND CHARDONNAY</h3>
                          <h3 className="text-align-center">IN THE HEART OF DUNDEE HILLS WINE COUNTRY</h3>
                        </div>
                      </div>
                      <div className="sqs-block horizontalrule-block sqs-block-horizontalrule" data-block-type={47} id="block-yui_3_17_2_1_1528212858018_18674">
                        <div className="sqs-block-content">
                          <hr />
                        </div>
                      </div>
                      <div className="sqs-block html-block sqs-block-html" data-block-type={2} id="block-yui_3_17_2_1_1528212858018_25259">
                        <div className="sqs-block-content">
                          <p className="text-align-center">This vineyard has been our family’s mission since we found our
                          20-acres – an ancient pile of fractured basalt – in the Dundee Hills in 1989. And since,
                          through years of clearing and planting, supply of superior
                          winegrapes to several winery partners, and creation of our own hands-on small-batch bottlings,
                                our goals have remained the same: plant on <strong>steep</strong> slopes, grow <strong>deep</strong> roots, deliver
                                <strong> intense</strong> flavors. Enjoy.
                              </p>
                        </div>
                      </div>
                      <div className="sqs-block button-block sqs-block-button" data-block-type={53} id="block-yui_3_17_2_1_1524081556566_3862">
                        <div className="sqs-block-content" id="yui_3_17_2_1_1538621009020_94">
                          <div className="sqs-block-button-container--center" data-alignment="center" data-button-size="medium" id="yui_3_17_2_1_1538621009020_93">
                            <a href="./vines" className="sqs-block-button-element--medium sqs-block-button-element" data-initialized="true">The Anderson Vineyard Story</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="visit-us" className="Index-page Index-page--has-image">
              <div className="Index-page-content  sqs-alternate-block-style-container">
                <div className="sqs-layout sqs-grid-12 columns-12" data-type="page" data-updated-on={1529705942825} id="page-5ad770921ae6cf8659265ad8">
                  <div className="row sqs-row">
                    <div className="col sqs-col-12 span-12">
                      <div className="sqs-block html-block sqs-block-html" data-block-type={2} id="block-c2dc0b66ff706542d4a2">
                        <div className="sqs-block-content">
                          <h1 className="text-align-center">VISIT US</h1>
                          <h3 className="text-align-center">Our approach is selling directly to a
                          select, well-served group
                          of customers. If you like our wines, you might be one of those. So
                          come see us, taste and
                          find out. Anderson Vineyard is located at 20120 NE Herring Lane in
                          Newberg. Please make an
                          appointment below.
                                </h3>
                        </div>
                      </div>
                      <div className="sqs-block button-block sqs-block-button" id="block-yui_3_17_2_1_1528251163802_7684">
                        <div className="sqs-block-content" id="yui_3_17_2_1_1538621009020_102">
                          <div className="sqs-block-button-container--center" id="yui_3_17_2_1_1538621009020_101">
                            <a href="https://friends.andersonfamilyvineyard.com/public/visit" className="sqs-block-button-element--medium sqs-block-button-element">MAKE YOUR APPOINTMENT</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <figure className="Index-page-image loaded" style={{ overflow: 'hidden' }}>
                <img alt="barrels-dark.jpg" src={data.barrelsDark.childImageSharp.original.src} style={{ fontSize: 0, left: 0, width: '100%', height: '1268.92px', position: 'relative', objectFit: 'cover', transform: 'translate(0%, -10%)' }} data-image-resolution="2500w" />
              </figure>
            </section>
            <nav className="Index-nav overlay">
              <div className="Index-nav-inner">
                <a href="#steep-deep-intense" className="Index-nav-item active" onClick={this.handleClick}>
                  <div className="Index-nav-indicator" />
                  <div className="Index-nav-text"><span>Steep Deep Intense</span></div>
                </a>
                <a href="#our-story" className="Index-nav-item" onClick={this.handleClick}>
                  <div className="Index-nav-indicator" />
                  <div className="Index-nav-text"><span>Our Story</span></div>
                </a>
                <a href="#visit-us" className="Index-nav-item" onClick={this.handleClick}>
                  <div className="Index-nav-indicator" />
                  <div className="Index-nav-text"><span>Visit Us</span></div>
                </a>
              </div>
            </nav>
          </main>
        </div>
      </div>
    </Layout>
    )
  }
}

export const query = graphql`
query {
    file(relativePath: { eq: "ui-icons.svg" }) {
       publicURL
    }
    afvHero: file(relativePath: {eq: "afv_hero.png"}) {
      childImageSharp {
        original {
            src
        }
      }
    }
    barrelsDark: file(relativePath: {eq: "barrels-dark.jpg"}) {
      childImageSharp {
        original {
            src
        }
      }
    }
  }
`

export default IndexPage